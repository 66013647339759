import React from 'react'
import servicio_empleo from '../../global/imagenes/portal/servicio_empleo.png';
import nos_acerca from '../../global/imagenes/portal/nos_acerca.png';

export const ImgPortalPiel = () => {
    return (
        <div style={{
            position: 'fixed',
            bottom: '20px',
            right: '200px',
            zIndex: '2',
            display: 'flex',
            justifyContent: 'flex-end'
        }}>
            <img src={servicio_empleo} className="logoSE" style={{ padding: '5px' }} />
            <img src={nos_acerca} className="logoNAT" style={{ padding: '5px' }} />
        </div>
    )
}
