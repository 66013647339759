import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import BuscarSelect from "../general/BuscarSelect";
import { EPS_OTRO } from "./helper";

export default function InscripcionAgregarMiContacto({ props_c }) {
  let {
    departamentos,
    departamento,
    setDepartamento,
    ciudades,
    ciudad,
    setCiudad,
    estratos,
    estrato,
    setEstrato,
    onChange,
    barrio,
    direccion,
    opcionesEps,
    nombreEps,
    setNombreEps,
    nombreEpsOtro,
    mostrar_ciudades,
  } = props_c;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Datos de Contacto
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BuscarSelect
            datos={departamentos}
            cambiarEstado={(value) => setDepartamento(value)}
            valor={departamento}
            id="select-departamento"
            nombre="¿En que departamento vives?"
            placeholder="Seleccione Opción"
          />
        </Grid>
        <Grid item xs={12}>
          <BuscarSelect
            datos={ciudades}
            cambiarEstado={(value) => setCiudad(value)}
            valor={ciudad}
            id="select-ciudad"
            nombre="¿En que ciudad o municipio vives?"
            placeholder={mostrar_ciudades ? "Seleccione Opción" : "Cargando información, por favor espere..."}
            habilitado={mostrar_ciudades ? true : false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={barrio}
            onChange={onChange}
            name="barrio"
            required
            id="barrio"
            label="¿En que barrio vives?"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={direccion}
            onChange={onChange}
            name="direccion"
            required
            id="direccion"
            label="¿Cual es tu dirección de residencia?"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <BuscarSelect
            datos={estratos}
            cambiarEstado={(value) => setEstrato(value)}
            valor={estrato}
            id="select-estrato"
            nombre="¿Cual es el estrato de tu vivienda?"
            placeholder="Seleccione Opción"
          />
        </Grid>
        <Grid item xs={12}>
          <BuscarSelect
            datos={opcionesEps}
            cambiarEstado={(value) => setNombreEps(value)}
            valor={nombreEps}
            id="select-eps"
            nombre="¿A que EPS perteneces?"
            placeholder="Seleccione Opción"
          />
        </Grid>
        {nombreEps && nombreEps.value === EPS_OTRO && (
          <Grid item xs={12}>
            <TextField
              value={nombreEpsOtro}
              required
              id="nombre_eps"
              name="nombreEpsOtro"
              label="Escriba nombre de la EPS"
              fullWidth
              autoComplete="fname"
              onChange={onChange}
            />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
