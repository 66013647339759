
import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import TareasTerminadas from "./../general/TareasTerminadas";
import emma_s from "../../global/imagenes/emma_s1.png";
import AlertasSimple from './../general/AlertasSimple';
import {  actualizarMensaje } from "../../redux/actions/actGlobal";

import MensajeInicio from "./ReinTransInicio/MensajeInicio";
import RegistrarForm from "./ReinTransInicio/RegistrarFormRT";

import { mostrarModalMensajeValidacionId } from "./../../redux/actions/actMatriculas";
import { mostrarModalDel } from "./../../redux/actions/actMatriculas";

import { obtenerValoresFiltros, crear_form_data, formulario, mostrarError } from "../../global/js/funciones";
import ConfirmarAccion from '../general/ConfirmarAccion';

import { TIPOS_ESTUDIO, EPSS } from '../matriculas/matriculas/helper';

class MatriculasReintTrans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      iniciar: false,
      cargando : false,
      mensaje_contacto: 'tecnologia@cuc.edu.co',
      mensaje_final: '',
      tipo_identificacion: null,
      tipos_identificacion: [],
      identificacion: null,
      epss: [],
      eps: null,
      primer_nombre: '',
      segundo_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      direccion_residencia: '',
      ciudad_nacimiento: '',
      ciudad_residencia: '',
      correo_electronico: '',
      fecha_nacimiento: '', 
      autorizo: 0,
      telefono: null,
      celular: null,
      existe_db: false,
      numero_creditos: null, 
      institucion_procedencia: '', 
      programa_procedencia: '', 
      programas: [], 
      tipo_estudios: [],
      programa_select: null, 
      tipo_est_select: null,
      guardado: false,
      enviando: false,
      total: 0, 
      archivos: [],
      existe_correo: false,
      isChecked1:false,
      isChecked2:false,
    }
  }

  ChangeState = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  guardarRT = async () => {
    let { mostrarModalDel, actualizarMensaje } = this.props;
    actualizarMensaje({ titulo: 'Enviado datos, por favor espere...', tipo: 'info', mostrar: true, tiempo: 10000 });
    let {tipo_identificacion, primer_nombre, segundo_nombre, identificacion, primer_apellido, segundo_apellido, direccion_residencia, ciudad_residencia, correo_electronico,fecha_nacimiento,autorizo, telefono, celular, numero_creditos, institucion_procedencia, programa_procedencia, programa_select, tipo_est_select, ciudad_nacimiento, archivos, eps} = this.state;
    let usuario = identificacion
    let data = await crear_form_data({
      tipo_identificacion: tipo_identificacion.value,
      identificacion,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      ciudad_nacimiento,
      fecha_nacimiento,
      direccion_residencia,
      ciudad_residencia,
      correo: correo_electronico,
      autorizo,
      telefono,
      celular,
      numero_creditos,
      institucion_procedencia,
      programa_procedencia,
      carrera: programa_select.value,
      tipo_estudio: tipo_est_select.value,
      usuario,
      eps: eps.value
    });
    data.append(`total`, archivos.length);
    archivos.map((file, index) => data.append(`archivo${index}`, file));
    this.ChangeState('enviando', true);
    formulario("api/v1.0/matriculas/crearRT", data, "post",
      async (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.ChangeState('guardado', true);
            this.ChangeState('enviando', false);
            mostrarModalDel(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }, false
    );
  }


  onChange = ({ target }) => {
    let { value, name } = target;
    if (name !== 'autorizo' && name !== 'archivos') {
      this.setState({
        [name]: value.replace(/[À-ÿ]/g, '')
      })
    }else if(name === 'autorizo' || name === 'archivos'){
      this.setState({
      [name]: value
    })
    }
  }

  validarDatos = (step) => {
    let {tipo_identificacion,primer_nombre,identificacion,primer_apellido,direccion_residencia,ciudad_residencia,correo_electronico,fecha_nacimiento,autorizo, telefono, celular, existe_db, numero_creditos, institucion_procedencia, programa_procedencia, programa_select, tipo_est_select, ciudad_nacimiento, total, archivos, existe_correo, eps} = this.state;
    let mensaje = '';
    if(step === 1) {
    }

    if(step === 0) {
      if (!tipo_identificacion) mensaje = 'Seleccione tipo identificación.';
      if (!identificacion) mensaje = 'Ingrese su identificación.';
      if (!eps) mensaje = 'Ingrese su esp.';
      else if (!primer_nombre) mensaje = 'Ingrese su primer nombre.';
      // else if (!segundo_nombre) mensaje = 'Ingrese su segundo nombre.';
      else if (!primer_apellido) mensaje = 'Ingrese su primer apellido.';
      // else if (!segundo_apellido) mensaje = 'Ingrese su segundo apellido.';
      else if (!direccion_residencia) mensaje = 'Ingrese su dirección de residencia.';
      else if (!ciudad_residencia) mensaje = 'Ingrese su ciudad de residencia.';
      else if (!correo_electronico) mensaje = 'Ingrese su correo electronico.';
      else if (!fecha_nacimiento) mensaje = 'Ingrese su fecha de nacimiento.';
      else if (!ciudad_nacimiento) mensaje = 'Ingrese su ciudad de nacimiento.';
      else if (!telefono) mensaje = 'Ingrese su telefono.';
      else if (!celular) mensaje = 'Ingrese su celular.';
      else if (!autorizo) mensaje = 'Para continuar es necesario aceptar el tratamiento de datos personales.';
      else if (existe_db) mensaje = 'Su identificación ya se encuentra registrada en nuestro sistema, por lo que no es posible tomar su solicitud por este medio.';
      else if (existe_correo) mensaje = 'Su correo ya se encuentra registrado en nuestro sistema, por lo que no es posible tomar su solicitud con este correo.';
    }else if(step === 1){
      if(numero_creditos < 1 || numero_creditos > 21 ) mensaje = 'Ingrese una cantidad de créditos valido.';
      else if(!institucion_procedencia) mensaje = 'Ingrese una institución de procedencia.'; 
      else if (!programa_procedencia) mensaje = 'Ingrese un programa de procedencia';
      else if (!programa_select) mensaje = 'Seleccione un programa.';
      else if(!tipo_est_select) mensaje = 'Seleccione un tipo de estudio.';
    }
    return mensaje;
  }

  cargarTipos = async () => {
    this.setState({cargando: true})
    const transformar = data => {
      return data.map(e => ({
        value: e.id,
        label: e.nombre,
        codigo: e.codigo,
        valorc: e.valorc
      }));
    }
    const tipos_identificacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 2 }, { 'llave': 'valora', 'valor': 'persona' }]);
    const programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 5 }]);
    const epss = await obtenerValoresFiltros([{"llave": 'generica', 'valor': EPSS}, { 'llave': 'valore', 'valor': 1 }]);
    const tipo_estudios = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_ESTUDIO}, { 'llave': 'valorb', 'valor': 'ReintTrans'}]);
    const data = await obtenerValoresFiltros([{ llave: "codigo", valor: "Mens_Prog_Virt" }])
    this.setState({ tipos_identificacion: transformar(tipos_identificacion), programas: transformar(programas), tipo_estudios: transformar(tipo_estudios), epss: transformar(epss), cargando : false })
  }

  componentDidMount() {
    this.cargarTipos();
  }

  render() {
    let {mostrarModalMensajeValidacionId, modalMensajeValidacionId, actualizarMensaje, modalDel, mostrarModalDel} = this.props;
    let {cargando, iniciar, mensaje_final, tipos_identificacion, tipo_identificacion, primer_nombre,segundo_nombre,primer_apellido,segundo_apellido,direccion_residencia,ciudad_residencia,correo_electronico,fecha_nacimiento,autorizo, telefono, celular, existe_db, numero_creditos, institucion_procedencia, programa_procedencia, programas, tipo_estudios, programa_select, tipo_est_select, ciudad_nacimiento, total, archivos, guardado, isChecked1,isChecked2, mensaje_contacto, epss, eps} = this.state;
    const mensajeAlerta = `En caso de que presentes algún inconveniente en el momento de realizar tu proceso de Reintegro o Transferencia, ${mensaje_contacto}.`
    if(cargando){
      return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_s} widthImg="7%" cargando={true}/>;
    }
    if(iniciar){
      return(
        <div>
        <ConfirmarAccion
          mensaje2=''
          ejecutarAccion={this.guardarRT} mostrarModalConfAccion={mostrarModalDel} modalConAccion={modalDel} dato={{ id: 0 }} titulo={'¿ Enviar Datos ?'} />
          <RegistrarForm props_c={{ terminarProceso: mostrarModalDel, setExisteBD: (existe_db) => { this.setState({ existe_db }) }, setFecha_nacimiento: (fecha_nacimiento) => { this.setState({ fecha_nacimiento }) }, setTipo_identificacion: (value) => { this.ChangeState('tipo_identificacion', value) }, mensaje_final, tipos_identificacion, setEps: (value) => { this.ChangeState('eps', value) }, epss, eps, primer_nombre,segundo_nombre,primer_apellido,segundo_apellido,direccion_residencia,ciudad_residencia, mensajeAlerta, correo_electronico,fecha_nacimiento,autorizo, OnChange: this.onChange, validarDatos: this.validarDatos, actualizarMensaje, onChange: this.onChange, mostrarModalMensajeValidacionId, modalMensajeValidacionId, ChangeState: this.ChangeState, telefono, celular, existe_db, numero_creditos, institucion_procedencia, programa_procedencia, programas, tipo_estudios, programa_select, tipo_est_select, setTipoEstudio: (value) => { this.ChangeState('tipo_est_select', value) }, setProgramaSeleccionado: (value) => { this.ChangeState('programa_select', value) }, ciudad_nacimiento, total, archivos, setArchivos: (value) => { this.ChangeState('archivos', value) }, guardado, setExisteBD_Correo: (existe_correo) => { this.setState({ existe_correo }) },setIsChecked1: (isChecked1) => { this.setState({ isChecked1 }) },setIsChecked2: (isChecked2) => { this.setState({ isChecked2 })},isChecked1, isChecked2}} />
        </div>
      )
    } else {
      return <MensajeInicio mensaje={mensajeAlerta} accion={() => this.ChangeState('iniciar', true)} />
    }
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalDel } = state.redGenericas;
  let { modalMensajeValidacionId } = state.redInscripciones;
  return {
    modalDel,
    modalMensajeValidacionId,
  }
};
//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModalMensajeValidacionId,
  mostrarModalDel
}

MatriculasReintTrans.propTypes = {
  //variables
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalMensajeValidacionId: PropTypes.func.isRequired,
  mostrarModalDel: PropTypes.func.isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(MatriculasReintTrans);
