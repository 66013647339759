import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
//FUNCIONES GENERALES
import { consulta, crear_form_data, formulario, mostrarError, generarFiltros, obtenerValoresFiltros, transformar, obtenerValores, coloresEmma } from "../../../global/js/funciones";
import { mostrarModulo, actualizarMensaje } from "../../../redux/actions/actGlobal";
//ACCIONES DEL MODULO
import {
  mostrarModalDetalleInscripcion, mostrarModalAddFiltrosInscripcion,
  mostrarModalGestionarInscripcion, mostrarModalEditarInscripcion,
  mostrarModalPeriodoInscripcion, mostrarModalDetalleInscripcionPre,
  mostrarModalGestionPreUniversitario, mostrarModalObservacionPreUniversitario, mostrarModalAsignarPreUniversitario, mostrarPlanAcompanamiento, mostrarModalPlan
} from "../../../redux/actions/actInscripciones";
//COMPONENTES DEL MODULO
import Inscripcion from "./Inscripcion";
import InscripcionPreUniversitario from "./InscripcionPreUniversitario";
import InscripcionEditar from "./InscripcionEditar";
import InscripcionesGestionar from "./InscripcionesGestionar";
import PintarPlanAcompanamiento from "./PintarPlanAcompanamiento";
import InscripcionesFiltrar from "./InscripcionesFiltrar";
import InscripcionesPermisos from './InscripcionesPermisos';
import InscripcionesObservacionPreUniversitario from './InscripcionesObservacionPreUniversitario';
import InscripcionesGestionarAdminEdu from './InscripcionesGestionarAdminEdu';
import { MenuAdministrar } from "./InscripcionesMenu";
import ListarDatos from "./../../general/ListarDatos";
import TareasTerminadas from "./../../general/TareasTerminadas";
import emma_w from './../../../global/imagenes/emma_w.png';
import moment from 'moment'
import emma_s1 from '../../../global/imagenes/emma_s1.png';
import TituloAccion from '../../general/TituloAccion';
import FilterListIcon from '@material-ui/icons/FilterList';
import AccionesRapidas from '../../general/AccionesRapidas';
import AppBarModal from '../../general/AppBarModal'
import {
  BtnFolder, BtnRegresar, BtnForm, BtnGestionar, BtnDetalle,
  BtnEditar, BtnVer, BtnAprobar, BtnSeleccion
} from "../../general/BotonesAccion";
import SearchIcon from '@material-ui/icons/Search';


import { orange } from '@material-ui/core/colors';
import { api } from '../../../global/js/funciones';
import {
  Card, CardContent, Typography, Paper, Grid, Tooltip, IconButton, InputLabel, MenuItem,
  FormControl, DialogActions, DialogContent, Select, Dialog, CardHeader, Avatar
} from '@material-ui/core'
import { JORNADAPREINSCRIPCION, LINEAEDU, COSTEAR_ESTUDIOS, TIPO_INSCRIPCIONES } from '../helper';

import { mostrarModalAdd } from "../../../redux/actions/actGenericas";
import InputBuscar from '../../general/InputBuscar';
import BuscarPersona from '../../general/BuscarPersona';
import BuscarPersonaIns from './BuscarPersonaIns';



function EditarPeriodo(component_props) {
  let { mostrarModalPeriodoInscripcion, modalPeriodoInscripcion, periodo, enviarDatos } = component_props
  return (
    <Dialog open={modalPeriodoInscripcion} maxWidth='sm' fullWidth={true} onClose={mostrarModalPeriodoInscripcion.bind(this, false)}>
      <AppBarModal titulo='¡ Actualizar al Periodo Actual !' mostrarModal={mostrarModalPeriodoInscripcion} titulo_accion='' />
      <DialogContent style={{ padding: 0 }} className='scroll' >
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Esta seguro de actualizar el periodo de inscripción del estudiante al periodo actual {periodo}
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Aceptar" callback={() => enviarDatos()} />
        <BtnForm texto="Cerrar" callback={() => mostrarModalPeriodoInscripcion(false)} />
      </DialogActions>
    </Dialog>
  )
}

function AgregarGestionPreUniversitario(component_props) {
  let { mostrarModalGestionPreUniversitario, modalGestionPreUniversitario, enviarDatosPreUniversitario, tipoGestion } = component_props
  if (tipoGestion === 'migestion') {
    return (
      <Dialog open={modalGestionPreUniversitario} maxWidth='sm' fullWidth={true} onClose={mostrarModalGestionPreUniversitario.bind(this, false)}>
        <AppBarModal titulo='¡ Agregar a mi Gestión !' mostrarModal={mostrarModalGestionPreUniversitario} titulo_accion='' />
        <DialogContent style={{ padding: 0 }} className='scroll' >
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Se va a agregar este registro a su gestión ¿Esta seguro?
              </Typography>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Aceptar" callback={() => enviarDatosPreUniversitario()} />
          <BtnForm texto="Cancelar" callback={() => mostrarModalGestionPreUniversitario(false)} />
        </DialogActions>
      </Dialog>
    )
  } else {
    return (
      <Dialog open={modalGestionPreUniversitario} maxWidth='sm' fullWidth={true} onClose={mostrarModalGestionPreUniversitario.bind(this, false)}>
        <AppBarModal titulo='¡  Enviar a Bandeja de Aceptados !' mostrarModal={mostrarModalGestionPreUniversitario} titulo_accion='' />
        <DialogContent style={{ padding: 0 }} className='scroll' >
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Se va a enviar este registro a la bandeja de gestion ¿Esta seguro?
              </Typography>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Aceptar" callback={() => enviarDatosPreUniversitario()} />
          <BtnForm texto="Cancelar" callback={() => mostrarModalGestionPreUniversitario(false)} />
        </DialogActions>
      </Dialog>
    )
  }

}

class Inscripciones extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tipoBusqueda: 'periodo',
      documentoEstudiante: null,
      seleccion: 'proceso',
      administrador: false,
      cargando: false,
      filtros: [],
      inscripciones: [],
      preuniversitario: [],
      periodo: "",
      inscripcion: null,
      estados_procesos: [],
      estados_procesosPre: [],
      periodos: [],
      filtrarPeriodo: false,
      accionProceso: "inicio",
      accionTarjeta: "inicio",
      filtros_inscripcion: [],
      cargarFiltroP: true,
      cargarFiltroE: true,
      periodos: [],
      periodo_filtro: null,
      periodo: [],
      filtrosTipoSolicitudes: [],
      filtrosTipoSolicitudesAdmin: [],
      mostrar: true,
      accionVistaDetalle: true,
      permisoPreuniversitario: '',
      consulta: [],
      tipoGestion: '',
      tipoDeEstadosCalificacionContacto: [],
      is_Ins_Env_Pre: false,
      is_Tod_Ins: false,
      buscarEstudiante: false,
      opciones_estudiante: [
        {
          value: "si",
          label: "SI",
        },
        {
          value: "no",
          label: "NO",
        },
      ],
      niveles_educacion: [
        {
          value: "tecnico",
          label: "TECNICO",
        },
        {
          value: "tecnologo",
          label: "TECNOLOGO",
        },
        {
          value: "profesional",
          label: "PROFESIONAL",
        },
      ],
      estudiante_11: '',
      nombre_colegio: '',
      nivel_educacion: '',
      carrera: '',
      lineas_edu_co: [],
      linea_edu: '',
      jornada_preinscripcion: [],
      jornada: '',
      otro_jornada: '',
      costear_estudio: '',
      costear_estudios: [],
      otro_costear: '',
      nombre_madre: '',
      nombre_padre: '',
      celular_madre: '',
      celular_padre: '',
      periodosAcademicos: [],
      peridoAcademicosSelected: '',
      estudiantes_plan: [],
    }
  }

  componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/inscripciones', nombre: 'inscripciones' })
    this.obtenerDatosIniciales()
    this.esAdministrador()
  }

  async componentDidUpdate({ }, { seleccion, accionProceso, accionTarjeta }) {
    let { seleccion: seleccionNew, accionProceso: accionProcesoNew, accionTarjeta: accionTarjetaNew, periodo_filtro } = this.state;

    if (seleccion !== seleccionNew) {
      if (seleccionNew === 'proceso') {
        this.setState({ accionProceso: "inicio" })
        this.actualizarDataInscripciones();
        this.obtenerDatos()
      }
    }
    if (accionTarjeta !== accionTarjetaNew) {
      if (accionTarjetaNew != 'inicio') {
        this.setState({ cargando: true })
        if (accionTarjetaNew === 'Tipo_PreUni') {
          let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Edu' }, { 'llave': 'estado', 'valor': '1' }]);
          let periodos = await this.obtenerPeriodos(2)
          let filtros = periodo.length > 0 ? [{ 'llave': 'estado_actual__valorb', 'valor': 'si' }, { 'llave': 'periodo', 'valor': periodo[0].nombre }] : []
          let filtrosTipoSolicitudes = await this.obtenerFiltrosTipoSolicitud()
          // let tipoDeEstadosCalificacionContacto = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': CALIFICACION_CONTACTO }]);
          const lineas_edu_co = await obtenerValores(LINEAEDU);
          const jornada_preinscripcion = await obtenerValores(JORNADAPREINSCRIPCION);
          const costear_estudios = await obtenerValores(COSTEAR_ESTUDIOS);
          // this.setState({ mostrar: true, periodo: periodo.length > 0 ? periodo[0].nombre : '', periodo_filtro: periodo.length > 0 ? periodo[0].nombre : '' })
          await this.obtenerEstadoSolicitudesPre(periodo[0].nombre)
          this.setState({ periodosAcademicos: this.transformarEnviadoPeriodos(periodos), filtrosTipoSolicitudes, periodo: periodo.length > 0 ? periodo[0].nombre : '', filtros, periodo_filtro: periodo.length > 0 ? periodo[0].nombre : '', mostrar: true, lineas_edu_co: transformar(lineas_edu_co), jornada_preinscripcion: transformar(jornada_preinscripcion), costear_estudios: transformar(costear_estudios) });
        } else if (accionTarjetaNew === 'Tipo_Ins' || accionTarjetaNew === 'Tipo_Homo') {
          let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Ins' }, { 'llave': 'estado', 'valor': '1' }]);
          let periodos = await this.obtenerPeriodos(2)
          let filtros = periodo.length > 0 ? [{ 'llave': 'estado_actual__valorb', 'valor': 'si' }, { 'llave': 'periodo', 'valor': periodo[0].nombre }] : []
          let filtrosTipoSolicitudes = await this.obtenerFiltrosTipoSolicitud()
          // let tipoDeEstadosCalificacionContacto = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': CALIFICACION_CONTACTO }]);
          const lineas_edu_co = await obtenerValores(LINEAEDU);
          const jornada_preinscripcion = await obtenerValores(JORNADAPREINSCRIPCION);
          const costear_estudios = await obtenerValores(COSTEAR_ESTUDIOS);
          // this.setState({ mostrar: false, periodo: periodo.length > 0 ? periodo[0].nombre : '', periodo_filtro: periodo.length > 0 ? periodo[0].nombre : '' })
          await this.obtenerEstadoSolicitudes(periodo[0].nombre, `tipoInscripcion=${accionTarjetaNew}`)
          this.setState({ periodosAcademicos: this.transformarEnviadoPeriodos(periodos), filtrosTipoSolicitudes, periodo: periodo.length > 0 ? periodo[0].nombre : '', filtros, periodo_filtro: periodo.length > 0 ? periodo[0].nombre : '', mostrar: false, lineas_edu_co: transformar(lineas_edu_co), jornada_preinscripcion: transformar(jornada_preinscripcion), costear_estudios: transformar(costear_estudios) });
        }
      } else {
        this.setState({ mostrar: true })
      }
    }

    if (accionProceso !== accionProcesoNew) {
      if (accionProcesoNew != 'Tod_Ins') {
        this.cambiarEstado({ cargando: true })
        if (accionProcesoNew == 'inicio') {
          this.setState({ is_Ins_Env_Pre: false })
          this.setState({ is_Tod_Ins: false })
          this.obtenerEstadoSolicitudes(periodo_filtro, `tipoInscripcion=${accionTarjetaNew}`)
          this.obtenerEstadoSolicitudesPre(periodo_filtro)
        } else {
          if (accionProcesoNew == 'Ins_Env_Pre') {
            this.setState({ is_Ins_Env_Pre: true })
          }

          this.actualizarDataInscripciones([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);

        }

      } else {
        this.cambiarEstado({ cargando: true })
        if (accionProcesoNew == 'inicio') {
          this.obtenerEstadoSolicitudes(periodo_filtro, `tipoInscripcion=${accionTarjetaNew}`)
          this.obtenerEstadoSolicitudesPre(periodo_filtro)

        } else {
          if (accionProcesoNew == 'Tod_Ins') {
            this.setState({ is_Tod_Ins: true })
          }
          this.actualizarDataInscripciones([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);

        }

      }
    }
  }

  async esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_ins_n1" || usuario.perfil.codigo === "Per_preuni_n1")) {
      this.setState({ administrador: true })
    }
    let consulta = await this.permisoPreuniversitarioObtener();
    if (consulta.length > 0) {
      this.setState({ permisoPreuniversitario: "1" })
    }
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm")) {
      let filtrosTipoSolicitudesAdmin = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': TIPO_INSCRIPCIONES }]);
      this.setState({ administrador: true, filtrosTipoSolicitudesAdmin })
    }
  }

  async actualizarDataInscripciones(filtros = [{ 'llave': 'estado_actual__valorb', 'valor': 'si' }, { 'llave': 'periodo', 'valor': this.state.periodo }]) {
    let { accionTarjeta } = this.state
    filtros.push({ 'llave': 'tipoBusqueda', 'valor': this.state.tipoBusqueda })
    filtros.push({ 'llave': 'documentoEstudiante', 'valor': this.state.documentoEstudiante })
    this.setState({ cargando: true, tipoBusqueda: 'periodo' })
    let inscripciones = []
    let preuniversitario = []
    if (this.state.tipoBusqueda === 'documento') {
      for (let i = 0; i < filtros.length; i++) {
        if (filtros[i].llave === 'periodo') {
          filtros.splice(i, 1);
        }
      }
    }

    if (accionTarjeta === 'Tipo_Ins' || accionTarjeta === 'Tipo_Homo') {
      filtros.push({ 'llave': 'tipoInscripcion', 'valor': accionTarjeta })
      inscripciones = await this.obtenerInscripciones(filtros);
    }
    if (accionTarjeta === 'Tipo_PreUni') {
      preuniversitario = await this.obtenerInscripcionesPre(filtros);
    }
    this.setState({ preuniversitario, inscripciones, cargando: false, filtros_inscripcion: filtros })
  }


  async obtenerDatos() {
    let filtrosTipoSolicitudes = await this.obtenerFiltrosTipoSolicitud()
    this.setState({ filtrosTipoSolicitudes })

  }

  async permisoPreuniversitarioObtener() {
    return new Promise(resolve => {
      consulta(`api/v1.0/inscripciones/preuniversitario/permiso`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  transformarEnviado(data) {
    return data.map(e => ({
      value: e.id,
      label: e.nombre,
      codigo: e.codigo,
    }));
  }

  transformarEnviadoPeriodos(data) {
    return data.map((e, index) => ({
      value: index,
      label: e.periodo,
      codigo: e.periodo,
    }));
  }

  async obtenerPeriodos(tipo) {
    return new Promise(resolve => {
      consulta(`api/v1.0/inscripciones/periodos/${tipo}`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    })
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Ins' }, { 'llave': 'estado', 'valor': '1' }]);
    let periodos = await this.obtenerPeriodos(1)
    let filtros = periodo.length > 0 ? [{ 'llave': 'estado_actual__valorb', 'valor': 'si' }, { 'llave': 'periodo', 'valor': periodo[0].nombre }] : []
    let filtrosTipoSolicitudes = await this.obtenerFiltrosTipoSolicitud()
    // let tipoDeEstadosCalificacionContacto = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': CALIFICACION_CONTACTO }]);
    // const lineas_edu_co = await obtenerValores(LINEAEDU);
    // const jornada_preinscripcion = await obtenerValores(JORNADAPREINSCRIPCION);
    // const costear_estudios = await obtenerValores(COSTEAR_ESTUDIOS);
    // this.obtenerEstadoSolicitudes(periodo[0].nombre);
    // this.obtenerEstadoSolicitudesPre(año[0].nombre);
    this.setState({ periodosAcademicos: this.transformarEnviadoPeriodos(periodos), periodo: periodo.length > 0 ? periodo[0].nombre : '', filtros, periodo_filtro: periodo.length > 0 ? periodo[0].nombre : '', filtrosTipoSolicitudes, cargando: false });
    // this.setState({ periodosAcademicos: this.transformarEnviadoPeriodos(periodos), filtrosTipoSolicitudes, periodo: periodo.length > 0 ? periodo[0].nombre : '', filtros, periodo_filtro: periodo.length > 0 ? periodo[0].nombre : '', 'tipoDeEstadosCalificacionContacto': transformar(tipoDeEstadosCalificacionContacto), lineas_edu_co: this.transformarEnviado(lineas_edu_co), jornada_preinscripcion: this.transformarEnviado(jornada_preinscripcion), costear_estudios: this.transformarEnviado(costear_estudios), cargando: false });
  }

  async obtenerFiltrosTipoSolicitud() {
    return new Promise(resolve => {
      consulta(`api/v1.0/inscripciones/tiposSolicitud`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerInscripciones(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/inscripciones/1?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }
  async obtenerInscripcionesPre(filtros = []) {
    let { accionProceso } = this.state
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/inscripciones/preuniversitario/1/${accionProceso}?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }


  enviarDatosPreUniversitario = (info) => {
    let { inscripcion, tipoGestion } = this.state;
    let data = {
      id_estudiante: inscripcion.estudiante.id,
      tipo: 'gestion',
      tipoGestion,
    }
    this.guardarDatosPreUniversitario(data, () => this.setState({ cargando: true, titulo_res: 'Cargando..' }));
    this.actualizarDataInscripciones(this.configFiltros(this.setState({ filtros_inscripcion: [] })))
    this.actualizarDataInscripciones()
  }



  enviarDatos = (info) => {
    let { inscripcion, periodo } = this.state;
    let data = {
      periodo,
      id_estudiante: inscripcion.estudiante.id,
      tipo: 'periodo',
    }
    this.guardarDatos(data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    this.actualizarDataInscripciones(this.configFiltros(this.setState({ filtros_inscripcion: [], accionProceso: 'inicio' })))
    this.actualizarDataInscripciones()
  }

  guardarDatos = async (data) => {
    let { actualizarMensaje, mostrarModalEditarInscripcion, mostrarModalPeriodoInscripcion } = this.props;
    return new Promise(resolve => {
      consulta(`api/v1.0/inscripciones/actualizar`, data, "post", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalEditarInscripcion(false);
            mostrarModalPeriodoInscripcion(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        resolve(estado === 200 && !error ? resp : null);

      })
    })
  }

  guardarDatosPreUniversitario = async (data) => {
    let { actualizarMensaje, mostrarModalGestionPreUniversitario, mostrarModalEditarInscripcion,
      mostrarModalObservacionPreUniversitario } = this.props;
    return new Promise(resolve => {
      consulta(`api/v1.0/inscripciones/preuniversitario/actualizar`, data, "post", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalGestionPreUniversitario(false);
            mostrarModalEditarInscripcion(false);
            mostrarModalObservacionPreUniversitario(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        resolve(estado === 200 && !error ? resp : null);

      })
    })
  }

  guardarDatosDocumentos = async (data) => {
    let { actualizarMensaje, mostrarModalEditarInscripcion, mostrarModalPeriodoInscripcion } = this.props;
    formulario(`api/v1.0/inscripciones/actualizar/documentos`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalEditarInscripcion(false);
          mostrarModalPeriodoInscripcion(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });

    })
  }

  gestionarInscripcion = async (id, data, callback) => {
    let { mostrarModalGestionarInscripcion, actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(`api/v1.0/inscripciones/${id}/gestionar`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalGestionarInscripcion(false);
          this.actualizarDataInscripciones(this.state.filtros_inscripcion);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      callback();
    })
  }


  gestionarPreuniversitarioEstados = async (id, data, callback) => {
    let { mostrarModalAsignarPreUniversitario, actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(`api/v1.0/inscripciones/preuniversitario/${id}/gestionar/estados`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalAsignarPreUniversitario(false);
          this.actualizarDataInscripciones(this.state.filtros_inscripcion);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      callback();
    })
  }

  changeState = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  cargarPlanesEstud = async (id) => {
    this.setState({ cargando: true });
    let data = await this.obtenerPlanAcompanamiento(id);
    this.setState({ estudiantes_plan: data, cargando: false });
    (this.state.estudiantes_plan) ? mostrarPlanAcompanamiento(true) : mostrarPlanAcompanamiento(true)
  }

  obtenerPlanAcompanamiento = (id) => {
    return new Promise((resolve) => {
      consulta(`api/v1.0/inscripcion/ObtenerEstudiantesPlan/${id}`, null, "get", (error, estado, resp) => {
        resolve(resp);
      });
    });
  }

  pintarProcesos = (periodoActual) => {
    let { mostrarModalDetalleInscripcion, mostrarModalEditarInscripcion, mostrarModalAddFiltrosInscripcion,
      mostrarModalGestionarInscripcion, modalGestionarInscripcion, actualizarMensaje,
      mostrarModalPeriodoInscripcion, mostrarModalObservacionPreUniversitario, mostrarPlanAcompanamiento, modalMostrarPlan, mostrarModalAdd, mostrarModalPlan, modalAgregarPlan } = this.props;
    let { inscripciones,
      inscripcion, periodo_filtro,
      permisoPreuniversitario,
      is_Tod_Ins, is_Ins_Env_Pre,
      estudiante_11,
      opciones_estudiante,
      nombre_colegio,
      nivel_educacion,
      niveles_educacion,
      carrera,
      linea_edu,
      lineas_edu_co,
      jornada_preinscripcion,
      jornada,
      otro_jornada,
      costear_estudios,
      costear_estudio,
      otro_costear,
      nombre_madre,
      nombre_padre,
      celular_madre,
      celular_padre,
      periodosAcademicos,
      peridoAcademicosSelected
    } = this.state
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Ins_Reg': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Ins_Pro': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Ins_Pen': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Ins_Env': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'Ins_Can': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        'Ins_Env_Pre': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        'Ins_Estu_Rea': { 'backgroundColor': coloresEmma.iconscolor, color },
        'Ins_Pag': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        // 'Ins_Ent_Prog': { 'backgroundColor': '#b71c1c', color },
      }
      return colores[codigo] || { 'backgroundColor': 'gray', 'color': 'black' };
    }

    const acciones = (data) => {
      let { estado_actual: { codigo }, permiso, periodo, datosGestion } = data;


      const onClickDetalle = (inscripcion) => {
        this.setState({ inscripcion })
        mostrarModalDetalleInscripcion(true);
      }

      const onClickEditar = (inscripcion) => {
        this.setState({ inscripcion, accionVistaDetalle: true })
        mostrarModalEditarInscripcion(true)
      }

      const onClickPeriodo = (inscripcion) => {
        this.setState({ inscripcion, periodo_filtro: periodoActual })
        mostrarModalPeriodoInscripcion(true)

      }

      const onClickGestionar = (inscripcion) => {
        this.setState({ inscripcion })
        mostrarModalGestionarInscripcion(true)
      }

      const onClickPlanAcompanamiento = async (inscripcion) => {
        this.setState({ inscripcion })
        let estudiantes_plan = await this.obtenerPlanAcompanamiento(inscripcion.estudiante.id);
        this.setState({ estudiantes_plan });
        (estudiantes_plan) ? mostrarPlanAcompanamiento(true) : mostrarPlanAcompanamiento(true)
      }

      const onClickObservacion = (inscripcion) => {
        this.setState({ inscripcion })
        mostrarModalObservacionPreUniversitario(true)
      }

      const onClickEditarGestion = (inscripcion) => {
        this.setState({ inscripcion, accionVistaDetalle: false })
        mostrarModalEditarInscripcion(true)
      }

      let editarGestion = (codigo === 'Ins_Env_Pre' && permiso === '1' && permisoPreuniversitario === '1' && datosGestion !== null) && <BtnFolder callback={() => onClickEditarGestion(data)} color={coloresEmma.primarycolor} texto='Gestion Tramitar' />;
      let observaciones = (codigo === 'Ins_Env_Pre' && permiso === '1' && permisoPreuniversitario === '1' && datosGestion !== null) && <BtnVer callback={() => onClickObservacion(data)} color={coloresEmma.secondarycolor} texto='Observaciones' />;
      let editar = (codigo !== 'Ins_Can' && codigo !== 'Ins_Env' && codigo !== 'Ins_Env_Pre' && permiso === '1') && !(this.state.accionTarjeta === 'Tipo_Homo' && this.state.accionProceso === 'Ins_Pro') && !(this.state.accionTarjeta === 'Tipo_Homo' && this.state.accionProceso === 'Ins_Estu_Rea') && !(this.state.accionTarjeta === 'Tipo_Homo' && this.state.accionProceso === 'Ins_Pag')  && <BtnEditar callback={() => onClickEditar(data)} color='#b71c1c' texto='Editar' />;
      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
      let periodoBtn = (codigo !== 'Ins_Can' && codigo !== 'Ins_Env' && codigo !== 'Ins_Env_Pre' && periodo !== periodoActual && permiso === '1') && <BtnFolder callback={() => onClickPeriodo(data)} color={coloresEmma.primarycolor} texto='Periodo' />;
      let gestionar = (permiso === '1') && !(this.state.accionTarjeta === 'Tipo_Homo' && this.state.accionProceso === 'Ins_Env') && !(this.state.accionTarjeta === 'Tipo_Homo' && this.state.accionProceso === 'Ins_Estu_Rea') && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;
      let plan = ((codigo !== 'Ins_Can' && codigo !== 'Ins_Env_Pre' && permiso === '1') && !(this.state.accionTarjeta === 'Tipo_Homo' && this.state.accionProceso === 'Ins_Reg')) && !(this.state.accionTarjeta === 'Tipo_Homo' && this.state.accionProceso === 'Ins_Pro') && <BtnSeleccion callback={() => onClickPlanAcompanamiento(data)} color='#00bfa5' texto='Plan Acompañamiento' />;
      return <div>{plan}{detalle}{editar}{gestionar}{periodoBtn}{editarGestion}{observaciones}</div>;
    }
    return (
      <div>
        <InscripcionesGestionar
          actualizarMensaje={actualizarMensaje}
          inscripcion={inscripcion}
          mostrarModalGestionarInscripcion={mostrarModalGestionarInscripcion} modalGestionarInscripcion={modalGestionarInscripcion}
          gestionarInscripcion={this.gestionarInscripcion}
          props_c={{
            accionTarjeta: this.state.accionTarjeta,
            accionProceso: this.state.accionProceso,
            estudiante_11,
            opciones_estudiante,
            nombre_colegio,
            nivel_educacion,
            niveles_educacion,
            carrera,
            linea_edu,
            lineas_edu_co,
            jornada_preinscripcion,
            jornada,
            otro_jornada,
            costear_estudios,
            costear_estudio,
            otro_costear,
            nombre_madre,
            nombre_padre,
            celular_madre,
            celular_padre,
            periodosAcademicos,
            peridoAcademicosSelected,
            onChange: this.onChange,
            setOpcion_estudiante: (value) => {
              this.changeState('estudiante_11', value)
            },
            setOpcion_nivelEducacion: (value) => {
              this.changeState('nivel_educacion', value)
            },
            setTipo_linea: (value) => {
              this.changeState('linea_edu', value)
            },
            setTipo_jornada: (value) => {
              this.changeState('jornada', value)
            },
            setOpcion_costearEstudio: (value) => {
              this.changeState('costear_estudio', value)
            },
            setOpcion_periodoAcademico: (value) => {
              this.changeState('peridoAcademicosSelected', value)
            },
          }}

        />
        <PintarPlanAcompanamiento
          mostrarPlanAcompanamiento={mostrarPlanAcompanamiento}
          modalMostrarPlan={modalMostrarPlan}
          mostrarModalAdd={mostrarModalAdd}
          inscripcion={inscripcion}
          actualizarMensaje={actualizarMensaje}
          mostrarModalPlan={mostrarModalPlan}
          modalAgregarPlan={modalAgregarPlan}
          estudiantes_plan={this.state.estudiantes_plan}
          obtenerPlanAcompanamiento={this.cargarPlanesEstud}
        />
        <ListarDatos
          datos={inscripciones}
          titulo='Lista de Inscripciones'
          id='tbl_historial_inscripciones'
          opciones={true}
          descargar='INSCRIPCIONES - EMMA'
          filtrar={true}
          refrescar={true}
          actfiltrar={() => mostrarModalAddFiltrosInscripcion(true)}
          actRefrescar={() => this.actualizarDataInscripciones(this.state.filtros_inscripcion)}
          acciones={(data) => acciones(data)}
          buscar={true}
          avatar={({ estado_actual }) => estado_actual.valora}
          color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
          fila_principal={({ estudiante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre.toUpperCase()} ${primer_apellido.toUpperCase()} ${segundo_apellido ? segundo_apellido.toUpperCase() : ''}`}
          filas={
            (is_Ins_Env_Pre == true) ? [
              // { 'id': 'ESTUDIANTE', 'mostrar': ({ estudiante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido ? segundo_apellido : ''}`, 'enLista': false },
              { 'id': 'PRIMER_NOMBRE', 'mostrar': ({ estudiante: { primer_nombre } }) => `${primer_nombre ? primer_nombre.toUpperCase() : ''}`, 'enLista': false },
              { 'id': 'SEGUNDO_NOMBRE', 'mostrar': ({ estudiante: { segundo_nombre } }) => `${segundo_nombre ? segundo_nombre.toUpperCase() : ''}`, 'enLista': false },
              { 'id': 'PRIMER_APELLIDO', 'mostrar': ({ estudiante: { primer_apellido } }) => `${primer_apellido ? primer_apellido.toUpperCase() : ''}`, 'enLista': false },
              { 'id': 'SEGUNDO_APELLIDO', 'mostrar': ({ estudiante: { segundo_apellido } }) => `${segundo_apellido ? segundo_apellido.toUpperCase() : ''}`, 'enLista': false },
              { 'id': 'GENERACION', 'mostrar': ({ estudiante: { correo } }) => correo, 'enLista': false },
              { 'id': 'CORREO', 'mostrar': ({ estudiante: { correo } }) => correo },
              { 'id': 'IDENTIFICACION', 'mostrar': ({ estudiante: { identificacion } }) => identificacion },
              { 'id': 'CURSA 11', 'mostrar': ({ estudiante_11 }) => estudiante_11, 'enLista': false },
              { 'id': 'COLEGIO', 'mostrar': ({ nombre_colegio }) => nombre_colegio, 'enLista': false },
              { 'id': 'CARRERA PRE ELEGIDA', 'mostrar': ({ carrera_pre_universitario }) => carrera_pre_universitario, 'enLista': false },
              { 'id': 'CARRERA ELEGIDA', 'mostrar': ({ carrera: { nombre } }) => `${nombre}` },
              { 'id': 'CELULAR', 'mostrar': ({ celular1 }) => celular1, 'enLista': false },
              { 'id': 'TELEFONO', 'mostrar': ({ telefono1 }) => telefono1, 'enLista': false },
              { 'id': 'DEPARTAMENTO', 'mostrar': ({ departamento: { nombre } }) => nombre, 'enLista': false },
              { 'id': 'CIUDAD', 'mostrar': ({ ciudad: { nombre } }) => nombre, 'enLista': false },
              { 'id': 'ESTRATO', 'mostrar': ({ estrato: { nombre } }) => nombre, 'enLista': false },
              { 'id': 'BARRIO', 'mostrar': ({ barrio }) => barrio, 'enLista': false },
              { 'id': 'DIRECCION', 'mostrar': ({ direccion }) => direccion, 'enLista': false },
              { 'id': 'FECHA REGISTRO', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
              { 'id': 'CODIGO PRUEBAS SABER 11', 'mostrar': ({ codigo_pruebas }) => codigo_pruebas, 'enLista': false },
              { 'id': 'PUNTAJE PRUEBAS SABER 11', 'mostrar': ({ puntaje_pruebas }) => puntaje_pruebas, 'enLista': false },
              { 'id': 'NOMBRE MADRE', 'mostrar': ({ nombre_madre }) => nombre_madre, 'enLista': false },
              { 'id': 'CELULAR MADRE', 'mostrar': ({ celular_madre }) => celular_madre, 'enLista': false },
              { 'id': 'NOMBRE PADRE', 'mostrar': ({ nombre_padre }) => nombre_padre, 'enLista': false },
              { 'id': 'CELULAR PADRE', 'mostrar': ({ celular_padre }) => celular_padre, 'enLista': false },
              { 'id': 'COLEGIO ASIGNADO', 'mostrar': ({ gestion_inscripcion }) => gestion_inscripcion.length > 0 ? gestion_inscripcion[0].colegio_asignado.nombre : 'sin gestionar', 'enLista': false },
              { 'id': 'CALIFICACION CONTACTO', 'mostrar': ({ gestion_inscripcion }) => gestion_inscripcion && gestion_inscripcion.length > 0 ? gestion_inscripcion[0].calificacion_contacto.nombre : 'sin gestionar' },
              { 'id': 'NOMBRE FINANCIERO', 'mostrar': ({ gestion_inscripcion }) => gestion_inscripcion && gestion_inscripcion.length > 0 ? gestion_inscripcion[0].nombre_financiero : 'sin gestionar', 'enLista': false },
              { 'id': 'TELEFONO FINANCIERO', 'mostrar': ({ gestion_inscripcion }) => gestion_inscripcion && gestion_inscripcion.length > 0 ? gestion_inscripcion[0].telefono_financiero : 'sin gestionar', 'enLista': false },
              { 'id': 'METODO PAGO', 'mostrar': ({ gestion_inscripcion }) => gestion_inscripcion && gestion_inscripcion.length > 0 ? gestion_inscripcion[0].metodo_pago.nombre : 'sin gestionar', 'enLista': false },
              { 'id': 'RESULTADO PREUNIVERSITARIO', 'mostrar': ({ gestion_inscripcion }) => gestion_inscripcion && gestion_inscripcion.length > 0 ? gestion_inscripcion[0].resultado_preuniversitario.nombre : 'sin gestionar', 'enLista': false },
              { 'id': 'estado_actual', 'mostrar': ({ estado_actual, gestor_nombre }) => `${estado_actual.nombre} ${gestor_nombre ? `${` - ${gestor_nombre}`}` : ''}` },
              { 'id': 'COMO CONOCIO', 'mostrar': ({ como_conocio }) => `${como_conocio.nombre}`, 'enLista': false }
            ] :
              [
                // { 'id': 'estudiante', 'mostrar': ({ estudiante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido ? segundo_apellido : ''}`, 'enLista': false },
                { 'id': 'PRIMER_NOMBRE', 'mostrar': ({ estudiante: { primer_nombre } }) => `${primer_nombre ? primer_nombre.toUpperCase() : ''}`, 'enLista': false },
                { 'id': 'SEGUNDO_NOMBRE', 'mostrar': ({ estudiante: { segundo_nombre } }) => `${segundo_nombre ? segundo_nombre.toUpperCase() : ''}`, 'enLista': false },
                { 'id': 'PRIMER_APELLIDO', 'mostrar': ({ estudiante: { primer_apellido } }) => `${primer_apellido ? primer_apellido.toUpperCase() : ''}`, 'enLista': false },
                { 'id': 'SEGUNDO_APELLIDO', 'mostrar': ({ estudiante: { segundo_apellido } }) => `${segundo_apellido ? segundo_apellido.toUpperCase() : ''}`, 'enLista': false },
                { 'id': 'identificacion', 'mostrar': ({ estudiante: { identificacion } }) => identificacion },
                { 'id': 'codigo_pruebas', 'mostrar': ({ codigo_pruebas }) => codigo_pruebas, 'enLista': false },
                { 'id': 'carrera', 'mostrar': ({ carrera: { nombre } }) => nombre, 'enLista': false },
                { 'id': 'Plan_Acomp_Matem', 'mostrar': ({ planAcom_Mate }) => planAcom_Mate, 'enLista': false },
                { 'id': 'Plan_Acomp_Lect', 'mostrar': ({ planAcom_Lect }) => planAcom_Lect, 'enLista': false },
                { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
                { 'id': 'estado_actual', 'mostrar': ({ estado_actual, gestor_nombre }) => `${estado_actual.nombre} ${gestor_nombre ? `${` - ${gestor_nombre}`}` : ''}` },
                { 'id': 'COMO CONOCIO', 'mostrar': ({ como_conocio }) => `${como_conocio.nombre}`, 'enLista': false }
              ]}
        />
      </div>
    );
  }
  pintarProcesosPre = () => {
    let { preuniversitario, inscripcion } = this.state
    let { mostrarModalAddFiltrosInscripcion, mostrarModalDetalleInscripcionPre,
      mostrarModalObservacionPreUniversitario, mostrarModalGestionPreUniversitario,
      mostrarModalEditarInscripcion, mostrarModalAsignarPreUniversitario } = this.props;
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Ins_Reg_Edu': { 'backgroundColor': coloresEmma.colorblanco, 'color': '#757575' },
        'Est_Ins_Asig_Edu': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'Ins_Acep_Edu': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Ins_Can': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        'Ins_Env_Pre': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Ins_Pre_Selec_Edu': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Ins_No_Selec_Edu': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      let { estado_actual: { codigo }, permiso } = data;
      const onClickDetalle = (inscripcion) => {
        this.setState({ inscripcion })
        mostrarModalDetalleInscripcionPre(true);
      }

      // const onClickEditar = (inscripcion) => {
      //   this.setState({ inscripcion, accionVistaDetalle:true })
      //   mostrarModalEditarInscripcion(true)
      // }

      const onClickEditar = (inscripcion) => {
        this.setState({ inscripcion, accionVistaDetalle: false, tipoGestion: 'migestion' })
        mostrarModalEditarInscripcion(true)
      }


      const onClickEnviarMiGestionar = (inscripcion) => {
        this.setState({ inscripcion, tipoGestion: 'migestion' })
        mostrarModalGestionPreUniversitario(true)

      }

      const onClickObservacion = (inscripcion) => {
        this.setState({ inscripcion })
        mostrarModalObservacionPreUniversitario(true)
      }

      const onClickEnviarGestionar = (inscripcion) => {
        this.setState({ inscripcion, tipoGestion: 'bandejaGestion' })
        mostrarModalGestionPreUniversitario(true)

      }
      const onClickAsignarGestion = (inscripcion) => {
        this.setState({ inscripcion })
        mostrarModalAsignarPreUniversitario(true)
      }

      let enviarMiGestion = (codigo === 'Ins_Acep_Edu' && permiso === '1') && <BtnGestionar callback={() => onClickEnviarMiGestionar(data)} color={coloresEmma.primarycolor} texto='Enviar a Mi Gestion' />;
      let gestion = (codigo === 'Ins_Reg_Edu' && permiso === '1') && <BtnAprobar callback={() => onClickEnviarGestionar(data)} color='#FA3F17' texto='Enviar Aceptados' />;
      let asignar = (codigo !== 'Ins_Can' && permiso === '1') && <BtnGestionar callback={() => onClickAsignarGestion(data)} color='#00bfa5' texto='Estados Gestion' />;

      // let editar =  (codigo !== 'Ins_Can' && codigo !== 'Ins_Env'  && codigo !== 'Ins_Env_Pre' && permiso === '1') && <BtnEditar callback={() => onClickEditar(data)} color='#b71c1c' texto='Editar'/>;
      let editar = (codigo !== 'Ins_Can' && codigo !== 'Ins_Env' && codigo !== 'Ins_Env_Pre' && permiso === '1') && <BtnFolder callback={() => onClickEditar(data)} color={coloresEmma.primarycolor} texto='Gestion Tramitar' />;
      let observaciones = (codigo === 'Est_Ins_Asig_Edu' && codigo !== 'Ins_Can' && permiso === '1') && <BtnVer callback={() => onClickObservacion(data)} color='#b71c1c' texto='Observaciones' />;
      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color={coloresEmma.filtrotodos} texto='Abrir' />;

      return <div>{detalle}{editar}{gestion}{enviarMiGestion}{observaciones}{asignar}</div>;
    }
    return (
      <div>
        <ListarDatos
          datos={preuniversitario}
          titulo='Lista de PreUniversitario'
          id='tbl_historial_preuniversitario'
          opciones={true}
          descargar='PREUNIVERSITARIO - EMMA'
          filtrar={true}
          refrescar={true}
          actfiltrar={() => mostrarModalAddFiltrosInscripcion(true)}
          actRefrescar={() => this.actualizarDataInscripciones(this.state.filtros_inscripcion)}
          acciones={(data) => acciones(data)}
          buscar={true}
          avatar={({ estudiante: { primer_nombre } }) => primer_nombre.charAt().toUpperCase()}
          color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
          fila_principal={({ estudiante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre.toUpperCase()} ${primer_apellido.toUpperCase()} ${segundo_apellido ? segundo_apellido.toUpperCase() : ''}`}
          filas={[
            // { 'id': 'ESTUDIANTE', 'mostrar': ({ estudiante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre.toUpperCase()} ${primer_apellido.toUpperCase()} ${segundo_apellido ? segundo_apellido.toUpperCase() : ''}`, 'enLista': false },
            { 'id': 'PRIMER_NOMBRE', 'mostrar': ({ estudiante: { primer_nombre } }) => `${primer_nombre ? primer_nombre.toUpperCase() : ''}`, 'enLista': false },
            { 'id': 'SEGUNDO_NOMBRE', 'mostrar': ({ estudiante: { segundo_nombre } }) => `${segundo_nombre ? segundo_nombre.toUpperCase() : ''}`, 'enLista': false },
            { 'id': 'PRIMER_APELLIDO', 'mostrar': ({ estudiante: { primer_apellido } }) => `${primer_apellido ? primer_apellido.toUpperCase() : ''}`, 'enLista': false },
            { 'id': 'SEGUNDO_APELLIDO', 'mostrar': ({ estudiante: { segundo_apellido } }) => `${segundo_apellido ? segundo_apellido.toUpperCase() : ''}`, 'enLista': false },
            { 'id': 'CORREO', 'mostrar': ({ estudiante: { correo } }) => correo ? correo.toUpperCase() : '' },
            { 'id': 'IDENTIFICACION', 'mostrar': ({ estudiante: { identificacion } }) => identificacion ? identificacion.toUpperCase() : '' },
            { 'id': 'CURSA 11', 'mostrar': ({ estudiante_11 }) => estudiante_11 ? estudiante_11.toUpperCase() : '', 'enLista': false },
            { 'id': 'COLEGIO', 'mostrar': ({ nombre_colegio }) => nombre_colegio ? nombre_colegio.toUpperCase() : '', 'enLista': false },
            { 'id': 'DEPARTAMENTO', 'mostrar': ({ departamento }) => departamento ? departamento.nombre.toUpperCase() : '', 'enLista': false },
            { 'id': 'CIUDAD', 'mostrar': ({ ciudad }) => ciudad ? ciudad.nombre.toUpperCase() : '' },
            { 'id': 'BARRIO', 'mostrar': ({ barrio }) => barrio ? barrio.toUpperCase() : '', 'enLista': false },
            { 'id': 'DIRECCION', 'mostrar': ({ direccion }) => direccion ? direccion.toUpperCase() : '', 'enLista': false },
            { 'id': 'LINEA EDU', 'mostrar': ({ linea_edu: { nombre } }) => nombre ? nombre.toUpperCase() : '', 'enLista': false },
            { 'id': 'CARRERA', 'mostrar': ({ carrera_pre_universitario }) => carrera_pre_universitario ? carrera_pre_universitario.toUpperCase() : '', 'enLista': false },
            { 'id': 'CELULAR', 'mostrar': ({ celular1 }) => celular1 ? celular1.toUpperCase() : '', 'enLista': false },
            { 'id': 'TELEFONO', 'mostrar': ({ telefono1 }) => telefono1 ? telefono1.toUpperCase() : '', 'enLista': false },
            { 'id': 'NIVEL EDUCACION', 'mostrar': ({ nivel_educacion }) => nivel_educacion ? nivel_educacion.toUpperCase() : '', 'enLista': false },
            { 'id': 'ESTRATO', 'mostrar': ({ estrato: { nombre } }) => nombre ? nombre.toUpperCase() : '', 'enLista': false },
            { 'id': 'NOMBRE MADRE', 'mostrar': ({ nombre_madre }) => nombre_madre ? nombre_madre.toUpperCase() : '', 'enLista': false },
            { 'id': 'CELULAR MADRE', 'mostrar': ({ celular_madre }) => celular_madre ? celular_madre.toUpperCase() : '', 'enLista': false },
            { 'id': 'NOMBRE PADRE', 'mostrar': ({ nombre_padre }) => nombre_padre ? nombre_padre.toUpperCase() : '', 'enLista': false },
            { 'id': 'CELULAR PADRE', 'mostrar': ({ celular_padre }) => celular_padre ? celular_padre.toUpperCase() : '', 'enLista': false },
            { 'id': 'JORNADA', 'mostrar': ({ jornada: { nombre } }) => nombre ? nombre.toUpperCase() : '', 'enLista': false },
            { 'id': 'OTRA JORNADA', 'mostrar': ({ otro_jornada }) => otro_jornada ? otro_jornada.toUpperCase() : '', 'enLista': false },
            { 'id': 'FECHA REGISTRO', 'mostrar': ({ fecha_registro }) => fecha_registro ? moment(fecha_registro).format('YYYY-MM-DD, h:mm a') : '' },
            { 'id': 'ESTADO ACTUAL', 'mostrar': ({ estado_actual, gestor_nombre }) => `${estado_actual ? estado_actual.nombre.toUpperCase() : ''} ${gestor_nombre ? `${` - ${gestor_nombre.toUpperCase()}`}` : ''}` },
            { 'id': 'COLEGIO ASIGNADO', 'mostrar': ({ datosGestion }) => datosGestion ? datosGestion.colegio_asignado.nombre.toUpperCase() : 'sin gestionar', 'enLista': false },
            { 'id': 'CALIFICACION CONTACTO', 'mostrar': ({ datosGestion }) => datosGestion ? datosGestion.calificacion_contacto.nombre.toUpperCase() : 'sin gestionar', 'enLista': false },
            { 'id': 'NOMBRE FINANCIERO', 'mostrar': ({ datosGestion }) => datosGestion ? datosGestion.nombre_financiero.toUpperCase() : 'sin gestionar', 'enLista': false },
            { 'id': 'TELEFONO FINANCIERO', 'mostrar': ({ datosGestion }) => datosGestion ? datosGestion.telefono_financiero.toUpperCase() : 'sin gestionar', 'enLista': false },
            { 'id': 'METODO PAGO', 'mostrar': ({ datosGestion }) => datosGestion ? datosGestion.metodo_pago.nombre.toUpperCase() : 'sin gestionar', 'enLista': false },
            { 'id': 'NOMBRE BANCO', 'mostrar': ({ datosGestion }) => datosGestion ? datosGestion.nombre_banco.toUpperCase() : 'sin gestionar', 'enLista': false },
            { 'id': 'RESULTADO PREUNIVERSITARIO', 'mostrar': ({ datosGestion }) => datosGestion ? datosGestion.resultado_preuniversitario.nombre.toUpperCase() : 'sin gestionar', 'enLista': false },
          ]}
        />
      </div>
    );
  }

  pintarInicioProcesos = () => {
    let { estados_procesos, filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo, cargandoEstudiante, filtroEstudiante, documentoEstudiante, buscarEstudiante } = this.state;
    let { mostrarModalAddFiltrosInscripcion } = this.props
    periodos.sort(function (a, b) {
      return a.periodo - b.periodo;
    });
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Ins_Reg': { 'backgroundColor': coloresEmma.colorblanco, 'color': '#757575' },
        'Ins_Pro': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'Ins_Pen': { 'backgroundColor':  coloresEmma.solicitudenrevision, color },
        'Ins_Env': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Ins_Can': { 'backgroundColor':  coloresEmma.solicitudcancelada, color },
        // 'Ins_Ent_Prog': { 'backgroundColor': coloresemma.solicitudcancelada, color },
        'Ins_Env_Pre': { 'backgroundColor': '#0d47w1', color },
        'Tod_Ins': { 'backgroundColor': coloresEmma.filtrotodos, color },
        'Ins_Pre_Selec_Edu': { 'backgroundColor': coloresEmma.primarycolor, color },
        'Ins_No_Selec_Edu': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        'Ins_Estu_Rea': { 'backgroundColor': coloresEmma.iconscolor, color },
        'Ins_Pag': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
      };

      return colores[codigo] || { 'backgroundColor': 'gray', 'color': 'black' };
    };

    const handleClick = (codigo) => {
      if (codigo != 'Tod_Ins') this.setState({ accionProceso: codigo })
      else {
        mostrarModalAddFiltrosInscripcion(true);
      }
    };

    const putInMiddle = (string, posicion, char) => {
      return string.slice(0, posicion) + char + string.slice(posicion, string.lenght)
    }

    if (this.state.accionTarjeta === 'Tipo_Homo') {
      estados_procesos = estados_procesos.map((estado) => estado.estado_actual__codigo === 'Ins_Pro' ? { ...estado, estado_actual__nombre: 'Procesado' } : estado)
    }

    return (
      <>
        <div style={{ padding: '2% 2% 0% 2%' }} >
          <Grid container direction={'row'} spacing={1} justify={'center'} >
            <Grid item xs={12} sm={12} md={12} lg={10} >
              <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                <Grid item xs={1} sm={1} md={1} lg={1} >
                  <BtnRegresar callback={() => this.setState({ accionTarjeta: 'inicio' })} />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} >
                  <Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
                  </Tooltip>
                  <Tooltip title='Buscar' aria-label="add" onClick={() => this.ModalBuscarEstudiante()} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><SearchIcon /></IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                {(estados_procesos.length === 0) ?
                  <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                  <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                    {estados_procesos.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol }, i) => {
                      return (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                          <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
                            <AccionesRapidas
                              imagen=''
                              titulo={`¡ ${nombre} !`}
                              sub_titulo=''
                              des_sub_titulo=''
                              avatar={obtenerColor(codigo)}
                              avatar_name={nombre.charAt(0).toUpperCase()}
                              list_desc={Number.isInteger(num_sol) ?
                                [`Numero de Solicitudes : ${num_sol}`] :
                                ['Debe aplicar los filtros.']}
                            />
                          </Paper>
                        </Grid>
                      )
                    })}
                  </Grid>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({ filtrarPeriodo: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({ filtrarPeriodo: false })} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            {!cargandoPeriodo ?
              <Grid container spacing={3} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                    <Select
                      inputProps={{
                        name: 'periodo_filtro',
                        id: 'periodo_filtro',
                      }}
                      value={periodo_filtro}
                      onChange={this.onChange}
                      required={true}>
                      {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{putInMiddle(periodo, 4, '-')}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' cargando={true} />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({ filtrarPeriodo: false })} />
            <BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
          </DialogActions>
        </Dialog >

        <Dialog fullWidth={true} maxWidth="xs" open={filtroEstudiante} onClose={() => this.cambiarEstado({ filtroEstudiante: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Filtro Estudiante !' mostrarModal={() => this.cambiarEstado({ filtroEstudiante: false })} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            <Grid container spacing={13} className='margin_cont' >
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                <FormControl className='form-control'>
                  <InputBuscar
                    id='estudiante'
                    label='Estudiante'
                    placeholder='Clic aquí para buscar'
                    value={documentoEstudiante}
                    onChange={(e) => this.cambiarEstado({ documentoEstudiante: e.target.value })}
                    callback={() => this.setState({ buscarEstudiante: true })} />
                  {/* <InputLabel htmlFor="estudianteBusqueda">Click aquí para buscar</InputLabel>
                  <Input
                    id="estudianteBusqueda"
                    onChange={(e) => this.cambiarEstado({ documentoEstudiante: e.target.value })}
                    color='secondary'
                    inputProps={{ 'aria-label': 'search' }}
                  /> */}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({ filtroEstudiante: false })} />
            <BtnForm texto="BUSCAR" callback={() => this.BuscarEstudiante(documentoEstudiante)} />
          </DialogActions>
        </Dialog >

        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarEstudiante} onClose={() => this.setState({ buscarEstudiante: false })}>
          <AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => this.setState({ buscarEstudiante: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarEstudiante: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            <BuscarPersonaIns
              callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
                documentoEstudiante: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
                buscarEstudiante: false
              })}
              buscarTipo={this.state.accionTarjeta}
              buscarTipoId={this.state.accionTarjetaId}
              buscarInscripcion={true}
            />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarEstudiante: false })} />
          </DialogActions>
        </Dialog>
      </>
    )
  }

  pintarInicioProcesosPre = () => {
    let { estados_procesosPre, filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo, cargandoEstudiante, filtroEstudiante, documentoEstudiante, buscarEstudiante } = this.state;
    let { mostrarModalAddFiltrosInscripcion } = this.props

    periodos.sort(function (a, b) {
      return a.periodo - b.periodo;
    });
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Ins_Reg_Edu': { 'backgroundColor': coloresEmma.colorblanco, 'color': '#757575' },
        'Ins_Acep_Edu': { 'backgroundColor': coloresEmma.solicitudInicada, color },
        'Ins_Reg': { 'backgroundColor': coloresEmma.colorblanco, 'color': '#757575' },
        'Ins_Pro': { 'backgroundColor': coloresEmma.solicitudInicada, color },
        'Ins_Pen': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'Ins_Env': { 'backgroundColor': coloresEmma.solicitudInicada, color },
        'Ins_Can': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        'Tod_Ins': { 'backgroundColor': coloresEmma.solicitudInicada, color },
        'Est_Ins_Asig_Edu': { 'backgroundColor': coloresEmma.solicitudAceptada, color },
        'Ins_Pre_Selec_Edu': { 'backgroundColor': coloresEmma.solicitudInicada, color },
        'Ins_No_Selec_Edu': { 'backgroundColor': coloresEmma.solicitudnoseleccionada, color },

      }
      return (colores[codigo])
    }

    const handleClick = (codigo) => {
      if (codigo != 'Tod_Ins') this.setState({ accionProceso: codigo })
      else {
        mostrarModalAddFiltrosInscripcion(true);
      }
    };

    const putInMiddle = (string, posicion, char) => {
      return string.slice(0, posicion) + char + string.slice(posicion, string.lenght)
    }

    return (
      <>
        <div style={{ padding: '2% 2% 0% 2%' }} >
          <Grid container direction={'row'} spacing={1} justify={'center'} >
            <Grid item xs={12} sm={12} md={12} lg={10} >
              <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                <Grid item xs={1} sm={1} md={1} lg={1} >
                  <BtnRegresar callback={() => this.setState({ accionTarjeta: 'inicio' })} />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} >
                  <Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
                  </Tooltip>
                  <Tooltip title='Buscar' aria-label="add" onClick={() => this.ModalBuscarEstudiante()} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><SearchIcon /></IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                {(estados_procesosPre.length === 0) ?
                  <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                  <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                    {estados_procesosPre.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol }, i) => {

                      return (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                          <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
                            <AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name={nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] : ['Debe aplicar los filtros.']} />
                          </Paper>
                        </Grid>
                      )
                    })}
                  </Grid>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({ filtrarPeriodo: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({ filtrarPeriodo: false })} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            {!cargandoPeriodo ?
              <Grid container spacing={3} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                    <Select
                      inputProps={{
                        name: 'periodo_filtro',
                        id: 'periodo_filtro',
                      }}
                      value={periodo_filtro}
                      onChange={this.onChange}
                      required={true}>
                      {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{putInMiddle(periodo, 4, '')}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' cargando={true} />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({ filtrarPeriodo: false })} />
            <BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
          </DialogActions>
        </Dialog >

        <Dialog fullWidth={true} maxWidth="xs" open={filtroEstudiante} onClose={() => this.cambiarEstado({ filtroEstudiante: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Filtro Estudiante !' mostrarModal={() => this.cambiarEstado({ filtroEstudiante: false })} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            <Grid container spacing={13} className='margin_cont' >
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                <FormControl className='form-control'>
                  <InputBuscar
                    id='estudiante'
                    label='Estudiante'
                    placeholder='Clic aquí para buscar'
                    value={documentoEstudiante}
                    onChange={(e) => this.cambiarEstado({ documentoEstudiante: e.target.value })}
                    callback={() => this.setState({ buscarEstudiante: true })} />
                  {/* <InputLabel htmlFor="estudianteBusqueda">Click aquí para buscar</InputLabel>
                  <Input
                    id="estudianteBusqueda"
                    onChange={(e) => this.cambiarEstado({ documentoEstudiante: e.target.value })}
                    color='secondary'
                    inputProps={{ 'aria-label': 'search' }}
                  /> */}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({ filtroEstudiante: false })} />
            <BtnForm texto="BUSCAR" callback={() => this.BuscarEstudiante(documentoEstudiante)} />
          </DialogActions>
        </Dialog >

        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarEstudiante} onClose={() => this.setState({ buscarEstudiante: false })}>
          <AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => this.setState({ buscarEstudiante: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarEstudiante: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              documentoEstudiante: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              buscarEstudiante: false
            })} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarEstudiante: false })} />
          </DialogActions>
        </Dialog>
      </>
    )
  }

  filtrarEstadoSolicitudes = async () => {
    let { periodo_filtro, accionTarjeta } = this.state
    if (accionTarjeta == 'Tipo_PreUni') await this.obtenerEstadoSolicitudesPre(periodo_filtro)
    else await this.obtenerEstadoSolicitudes(periodo_filtro)
    this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  obtenerEstadoSolicitudes = (periodo, extra = '') => {
    consulta(`api/v1.0/inscripcion/estados/${periodo}?${extra}`, null, null, (error, estado, resp) => {
      if (estado === 200) {
        let { general, extra } = resp
        if (extra.length > 0) {
          extra.map(({ solicitudes, nombre, codigo, orden }) => {
            let reporte = {
              'estado_actual__codigo': codigo,
              'estado_actual__nombre': nombre,
              'num_sol': solicitudes,
              'orden': orden
            }
            general.push(reporte);
          })
        }
        this.cambiarEstado({ estados_procesos: general.sort((a, b) => a.orden - b.orden), cargando: false });
      }
    })
  }

  obtenerEstadoSolicitudesPre = (periodo, extra = '') => {
    consulta(`api/v1.0/inscripcion/estados/preuniversitario/${periodo}?${extra}`, null, null, (error, estado, resp) => {
      if (estado === 200) {
        let { general, extra } = resp
        if (extra.length > 0) {
          extra.map(({ solicitudes, nombre, codigo, orden }) => {
            let reporte = {
              'estado_actual__codigo': codigo,
              'estado_actual__nombre': nombre,
              'num_sol': solicitudes,
              'orden': orden
            }
            general.push(reporte);
          })
        }
        this.cambiarEstado({ estados_procesosPre: general.sort((a, b) => a.orden - b.orden), cargando: false });
      }
    })
  }

  // obtenerPeriodos = () => {
  //   return new Promise(resolve => {
  //       consulta(`api/v1.0/inscripciones/periodos`, null, null,
  //           (error, estado, resp) => {
  //               resolve(resp)
  //           }
  //       );
  //   })
  // }

  ModalfiltroPeriodo = async () => {
    let { cargarFiltroP, periodo, accionTarjeta } = this.state
    this.cambiarEstado({ filtrarPeriodo: true })
    this.setState({ tipoBusqueda: 'periodo' })
    // if (cargarFiltroP) {
    // this.cambiarEstado({ cargandoPeriodo: true })
    let periodos = []
    if (accionTarjeta === 'Tipo_PreUni') {
      periodos = await this.obtenerPeriodos(2)
    } else {
      periodos = await this.obtenerPeriodos(1)
    }
    // if (!(periodos.find(e => e.periodo == periodo))) periodos.push({ 'periodo': periodo })
    this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
    // }
  }
  ModalBuscarEstudiante = async () => {
    this.cambiarEstado({ filtroEstudiante: true, documentoEstudiante: '' })
    this.setState({ tipoBusqueda: 'documento' })
    // Swal.fire({
    //   text: 'FILTRO ESTUDIANTE ',
    //   inputPlaceholder:'Digite aquí el documento del estudiante',
    //   input: 'text',
    //   showCancelButton: true,
    //   cancelButtonText: 'Cancelar',
    //   confirmButtonText: 'Buscar',
    //   confirmButtonColor: coloresEmma.primarycolor
    // }).then((DocumentoIdentidad) => {
    // if (!DocumentoIdentidad.isConfirmed || DocumentoIdentidad.value == '') return
    // this.setState({documentoEstudiante: DocumentoIdentidad.value, tipoBusqueda: 'documento'})
    // this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
    // this.obtenerEstadoSolicitudes(DocumentoIdentidad.value, "busqueda=documento")
    // this.obtenerEstadoSolicitudesPre(DocumentoIdentidad.value, "busqueda=documento")
    // })
  }

  BuscarEstudiante = async (DocumentoIdentidad) => {
    if (!DocumentoIdentidad || DocumentoIdentidad == '') return
    this.setState({ documentoEstudiante: DocumentoIdentidad, tipoBusqueda: 'documento' })
    this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
    this.obtenerEstadoSolicitudes(DocumentoIdentidad, `busqueda=documento&tipoInscripcion=${this.state.accionTarjeta}`)
    this.obtenerEstadoSolicitudesPre(DocumentoIdentidad, "busqueda=documento")
    this.cambiarEstado({ filtroEstudiante: false })
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  renderProcesos() {
    let { accionProceso, periodo, accionTarjeta } = this.state;
    let periodoActual = periodo
    if (accionTarjeta === 'Tipo_Ins' || accionTarjeta === 'Tipo_Homo') {
      return (
        <>
          {accionProceso === 'inicio' ? this.pintarInicioProcesos() : this.pintarProcesos(periodoActual)}
          {accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ filtros_inscripcion: [], accionProceso: 'inicio' })} /></div>}
        </>
      )
    } else {
      return (
        <>
          {accionProceso === 'inicio' ? this.pintarInicioProcesosPre() : this.pintarProcesosPre(periodoActual)}
          {accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ filtros_inscripcion: [], accionProceso: 'inicio' })} /></div>}
        </>
      )
    }
  }

  renderInicio() {
    let { usuario } = this.props;
    let { filtrosTipoSolicitudes, filtrosTipoSolicitudesAdmin } = this.state
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm")) {
      return (
        <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
          {filtrosTipoSolicitudesAdmin.map((datos, i) =>
            <Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} key={i} onClick={() => this.setState({ accionTarjeta: datos.codigo, accionTarjetaId: datos.id })}>
              <Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
                <CardHeader
                  avatar={<Avatar aria-label="recipe" style={{ backgroundColor: coloresEmma.iconscolor}}></Avatar>}
                  title={datos.nombre}
                />
                <CardContent >
                  <Grid container direction={'row'} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                      <img src={datos.archivo} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                      <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                        {datos.descripcion}
                      </Typography>
                    </Grid>
                  </Grid>

                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      )
    } else {
      return (
        <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
          {filtrosTipoSolicitudes.map((datos, i) =>
            <Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} key={i} onClick={() => this.setState({ accionTarjeta: datos.relacion.codigo, accionTarjetaId: datos.relacion.id })}>
              <Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
                <CardHeader
                  avatar={<Avatar aria-label="recipe" style={{ backgroundColor: orange[500] }}></Avatar>}
                  title={datos.relacion.nombre}
                />
                <CardContent >
                  <Grid container direction={'row'} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                      <img src={api + datos.relacion.archivo} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                      <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                        {datos.relacion.descripcion}
                      </Typography>
                    </Grid>
                  </Grid>

                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      )
    }
  }


  configFiltros = (accion_actual) => {
    let { periodo_filtro } = this.state
    switch (accion_actual) {
      case 'Tod_Ins':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      case 'inicio':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      default:
        return [{ 'llave': 'estado_actual__codigo', 'valor': accion_actual }, { 'llave': 'periodo', 'valor': periodo_filtro }]
    }
  }

  render() {
    let { mostrarModalDetalleInscripcion, mostrarModalDetalleInscripcionPre, mostrarModalPeriodoInscripcion,
      modalPeriodoInscripcion, modalDetalleInscripcion, modalDetalleInscripcionPre,
      mostrarModalEditarInscripcion, modalEditarInscripcion, actualizarMensaje,
      mostrarModalAddFiltrosInscripcion, modalAddFiltrosInscripcion, modalGestionPreUniversitario,
      mostrarModalGestionPreUniversitario, mostrarModalObservacionPreUniversitario,
      modalObservacionPreUniversitario, modalAsignarPreUniversitario, mostrarModalAsignarPreUniversitario } = this.props;

    let { cargando, inscripcion, seleccion, administrador, accionProceso, accionTarjeta, periodo,
      mostrar, accionVistaDetalle, tipoGestion, inscripciones, tipoDeEstadosCalificacionContacto, is_Ins_Env_Pre } = this.state;
    return (
      <div>
        <MenuAdministrar
          seleccionar={(seleccion) => this.setState({ seleccion })}
          sele={seleccion}
          administrador={administrador}
        />
        <div className='contenido_modulo'>
          <InscripcionesFiltrar
            actualizarMensaje={actualizarMensaje}
            modalAddFiltrosInscripcion={modalAddFiltrosInscripcion}
            mostrarModalAddFiltrosInscripcion={mostrarModalAddFiltrosInscripcion}
            filtrar={(filtros) => this.actualizarDataInscripciones(this.configFiltros(accionProceso).concat(filtros))}
            limpiar={() => this.actualizarDataInscripciones(this.configFiltros(accionProceso))}
            accionProceso={accionProceso}
            mostrarTodos={(seleccion) => this.setState({ accionProceso: seleccion })}
            mostrar={mostrar}
            inscripciones={inscripciones}
            tipoDeEstadosCalificacionContacto={tipoDeEstadosCalificacionContacto}
            is_Ins_Env_Pre={is_Ins_Env_Pre}
          />

          <Inscripcion
            inscripcion={inscripcion ? { id: inscripcion.id, tipo: inscripcion.tipo_registro } : { id: 0, tipo: '' }}
            mostrarModalDetalleInscripcion={mostrarModalDetalleInscripcion}
            modalDetalleInscripcion={modalDetalleInscripcion}
            vistaDetalle='inscripcion' />

          <InscripcionPreUniversitario
            inscripcion={inscripcion ? { id: inscripcion.id, tipo: inscripcion.tipo_registro } : { id: 0, tipo: '' }}
            mostrarModalDetalleInscripcionPre={mostrarModalDetalleInscripcionPre}
            modalDetalleInscripcionPre={modalDetalleInscripcionPre}
            vistaDetalle='inscripcion' />

          <InscripcionEditar
            inscripcion={inscripcion ? { id: inscripcion.id, tipo: inscripcion.tipo_registro } : { id: 0, tipo: '' }}
            mostrarModalEditarInscripcion={mostrarModalEditarInscripcion}
            modalEditarInscripcion={modalEditarInscripcion}
            actualizarMensaje={actualizarMensaje}
            vistaDetalle={accionTarjeta}
            accionVistaDetalle={accionVistaDetalle}
            guardarDatosDocumentos={this.guardarDatosDocumentos}
            guardarDatos={this.guardarDatos}
            guardarDatosPreUniversitario={this.guardarDatosPreUniversitario}
          />
          <InscripcionesObservacionPreUniversitario
            inscripcion={inscripcion ? { id: inscripcion.id, tipo: inscripcion.tipo_registro } : { id: 0, tipo: '' }}
            mostrarModalObservacionPreUniversitario={mostrarModalObservacionPreUniversitario}
            modalObservacionPreUniversitario={modalObservacionPreUniversitario}
            actualizarMensaje={actualizarMensaje}
            guardarDatosPreUniversitario={this.guardarDatosPreUniversitario}
          />
          <InscripcionesGestionarAdminEdu
            proceso={inscripcion ? inscripcion : 0}
            mostrarModalAsignarPreUniversitario={mostrarModalAsignarPreUniversitario}
            modalAsignarPreUniversitario={modalAsignarPreUniversitario}
            actualizarMensaje={actualizarMensaje}
            enviar={this.gestionarPreuniversitarioEstados}
          />

          <EditarPeriodo
            {...{
              modalPeriodoInscripcion,
              mostrarModalPeriodoInscripcion,
              periodo,
              enviarDatos: this.enviarDatos
            }}
          />
          <AgregarGestionPreUniversitario
            {...{
              modalGestionPreUniversitario,
              mostrarModalGestionPreUniversitario,
              tipoGestion,
              enviarDatosPreUniversitario: this.enviarDatosPreUniversitario
            }}
          />
          {
            !cargando ?
              <div>
                {seleccion === 'proceso' && accionTarjeta === 'inicio' && this.renderInicio()}
                {seleccion === 'proceso' && (accionTarjeta === 'Tipo_Ins' || accionTarjeta === 'Tipo_Homo') && this.renderProcesos()}
                {seleccion === 'proceso' && accionTarjeta === 'Tipo_PreUni' && this.renderProcesos()}
                {seleccion === 'administrar' && <InscripcionesPermisos />}

              </div> :
              <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let { modalDetalleInscripcion, modalDetalleInscripcionPre, modalEditarInscripcion, modalAddFiltrosInscripcion,
    modalPeriodoInscripcion, modalGestionarInscripcion, modalGestionPreUniversitario,
    modalObservacionPreUniversitario, modalAsignarPreUniversitario, modalTramitarInscripcion, modalMostrarPlan, modalAgregarPlan, } = state.redInscripciones;
  let { usuario } = state.redGlobal;
  return {
    modalDetalleInscripcion,
    modalEditarInscripcion,
    modalPeriodoInscripcion,
    modalGestionPreUniversitario,
    modalAddFiltrosInscripcion,
    modalGestionarInscripcion,
    modalDetalleInscripcionPre,
    modalObservacionPreUniversitario,
    modalAsignarPreUniversitario,
    modalTramitarInscripcion,
    modalMostrarPlan,
    modalAgregarPlan,
    usuario,
  };
};

const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModalDetalleInscripcion,
  mostrarModulo,
  mostrarModalAddFiltrosInscripcion,
  mostrarModalGestionarInscripcion,
  mostrarModalEditarInscripcion,
  mostrarModalPeriodoInscripcion,
  mostrarModalDetalleInscripcionPre,
  mostrarModalGestionPreUniversitario,
  mostrarModalObservacionPreUniversitario,
  mostrarModalAsignarPreUniversitario,
  mostrarPlanAcompanamiento,
  mostrarModalAdd,
  mostrarModalPlan,
};

Inscripciones.propTypes = {
  modalDetalleInscripcion: PropTypes.bool.isRequired,
  modalEditarInscripcion: PropTypes.bool.isRequired,
  mostrarModalDetalleInscripcion: PropTypes.func.isRequired,
  mostrarModalEditarInscripcion: PropTypes.func.isRequired,
  mostrarModalPeriodoInscripcion: PropTypes.func.isRequired,
  mostrarModalDetalleInscripcionPre: PropTypes.func.isRequired,
  mostrarModalGestionPreUniversitario: PropTypes.func.isRequired,
  mostrarModalObservacionPreUniversitario: PropTypes.func.isRequired,
  mostrarModalAsignarPreUniversitario: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalAdd: PropTypes.func.isRequired,
  mostrarModalPlan: PropTypes.func.isRequired,
  modalAgregarPlan: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Inscripciones);
